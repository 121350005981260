import { Form, Input, Select } from "antd";

export default () => {
  return [
    {
      label: "Овог",
      name: "lastName",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Нэр",
      name: "firstName",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },

    {
      label: "Email",
      name: "email",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Утасны дугаар",
      name: "mobile",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Анги",
      name: "grade",
      className: "col-span-12 md:col-span-6 mb-0",
    },
    {
      label: "Сургууль",
      name: "grade",
      className: "col-span-12 md:col-span-6 mb-0",
    },
    {
      label: "Instagram",
      name: "instagram",
      className: "col-span-12 md:col-span-6 mb-0",
    },
  ];
};
