import {
  BiUserCheck,
  BiIdCard,
  BiCog,
  BiGrid,
  BiBuilding,
  BiHorizontalLeft,
  BiHorizontalRight,
  BiCalendarPlus,
  BiDialpad,
  BiUserCircle,
  BiTransferAlt,
  BiBarChartAlt,
  BiDialpadAlt,
} from "react-icons/bi";
import { DashboardOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AuthContext } from "contexts";

export default [
  {
    label: "Элсэлт",
    type: "group",
    role: ["Админ", "Удирдлага", "Менежер"],
    hospital: [1, 2],
    icon: <BiIdCard size={16} className="inline text-[#F19920]" />,
    children: [
      {
        label: <Link to="/admissions/dashboard">Хянах самбар</Link>,
        icon: <DashboardOutlined size={16} className="inline text-[#F19920]" />,
        key: "admissions-dahboard",
        role: ["Админ", "Удирдлага", "Менежер"],
      },
      {
        label: <Link to="admissions/applicants">Applicants</Link>,
        icon: <BiUserCircle size={16} className="inline text-[#F19920]" />,
        key: "applicants",
        role: ["Админ", "Удирдлага", "Менежер", "Нягтлан"],
      },
    ],
  },
  {
    label: "Төлбөр",
    type: "group",
    role: ["Админ", "Удирдлага", "Менежер"],
    hospital: [1, 2],
    icon: <BiIdCard size={16} className="inline text-[#F19920]" />,
    children: [
      {
        label: <Link to="/tuition/dashboard">Хянах самбар</Link>,
        icon: <DashboardOutlined size={16} className="inline text-[#F19920]" />,
        key: "tuition-dahboard",
        role: ["Админ", "Удирдлага", "Менежер"],
      },
      {
        label: <Link to="/tuition/payers">Сурагчид</Link>,
        icon: <BiUserCircle size={16} className="inline text-[#F19920]" />,
        key: "tuition-payers",
        role: ["Админ", "Удирдлага", "Менежер", "Нягтлан"],
      },
      // {
      //   label: <Link to="/tuition/report">Төлбөрийн график</Link>,
      //   icon: <BiBarChartAlt size={16} className="inline text-[#F19920]" />,
      //   key: "tuition-stats",
      //   role: ["Админ", "Удирдлага", "Менежер", "Нягтлан"],
      // },
      {
        label: <Link to="/tuition/transactions">Төлбөрийн гүйлгээ</Link>,
        icon: <BiGrid size={16} className="inline text-[#F19920]" />,
        key: "tuition-bankTransaction",
        role: ["Админ", "Удирдлага", "Менежер", "Нягтлан"],
      },
    ],
  },
  {
    label: "Бусад",
    type: "group",
    role: ["Админ", "Удирдлага", "Менежер"],
    hospital: [1, 2],
    icon: <BiIdCard size={16} className="inline text-[#F19920]" />,
    children: [
      {
        label: <Link to="/essay/transaction">Бүх гүйлгээ</Link>,
        icon: <BiBarChartAlt size={16} className="inline text-[#F19920]" />,
        key: "transactions",
        role: ["Админ", "Удирдлага", "Менежер", "Нягтлан"],
      },
    ],
  },
  {
    label: "Үйл ажиллагаа",
    type: "group",
    role: ["Админ", "Удирдлага", "Менежер", "Багш"],
    hospital: [1, 2],
    icon: <BiIdCard size={16} className="inline text-[#F19920]" />,
    children: [
      {
        label: <Link to="/attendance/dashboard">Dashboard</Link>,
        icon: <BiBarChartAlt size={16} className="inline text-[#F19920]" />,
        key: "attendance-dashboard",
        role: ["Админ", "Удирдлага", "Менежер", "Нягтлан", "Багш"],
      },
      {
        label: <Link to="/student/attendance">Ирц</Link>,
        icon: <BiBarChartAlt size={16} className="inline text-[#F19920]" />,
        key: "attendances",
        role: ["Админ", "Удирдлага", "Менежер", "Нягтлан", "Багш"],
      },
      {
        label: <Link to="/attendance/report">Жагсаалт</Link>,
        icon: <BiBarChartAlt size={16} className="inline text-[#F19920]" />,
        key: "attendancesreport",
        role: ["Админ", "Удирдлага", "Менежер", "Нягтлан", "Багш"],
      },
    ],
  },

  {
    label: "Хүний нөөц",
    type: "group",
    role: ["Админ", "Удирдлага", "Менежер"],
    hospital: [1, 2],
    icon: <BiIdCard size={16} className="inline text-[#F19920]" />,
    children: [
      {
        label: <Link to="/employee">Ажилчид</Link>,
        key: "employee",
        role: ["Админ", "Удирдлага", "Менежер"],
        hospital: [1, 2],
        icon: <BiUserCircle size={16} className="inline text-[#F19920]" />,
      },
    ],
  },
];
